import ModalHeader from '../../modal-components/modal-header/ModalHeader';
import {handleClose, handleRedirect} from 'blockmate-react-link';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import {useEffect, useMemo, useState} from 'react';
import ExchangeSignIn from './exchange-sign-in/ExchangeSignIn';
import ConfirmPermissions from './confirm-permissions/ConfirmPermissions';
import LinkMessage from './link-message/LinkMessage';
// import EnterAmount from './enter-amount/EnterAmount';
import Preview from './preview/Preview';
import TransferDone from './transfer-done/TransferDone';
import DepositIntro from '../shared/deposit-intro/DepositIntro';
import FinalMessage from '../shared/final-message/FinalMessage';
import {useSearchParams} from 'react-router-dom';
import useApiInstances from '../../../services/api-call/axios';
import SearchProvider from '../../modal-components/search-provider/SearchProvider';
import {ProviderContext} from '../../../context/provider/ProviderContext';
import ProviderLogo from '../shared/common/ProviderLogo';
import Errors from '../shared/common/Errors';
import useDeposit from '../../../services/api-call/deposit';
import {valueExists} from '../../../utils/utils';
import WaitForRedirect from './wait-for-redirect/WaitForRedirect';
import ErrorModalScreen from '../shared/common/ErrorModalScreen';
import SecondFactor from './second-factor/SecondFactor';

const STEPS = {
	depositIntro: 0,
	selectProvider: 1,
	exchangeSignIn: 2,
	confirmPermissions: 3,
	linkMessage: 4,
	// enterAmount: 5,
	preview: 5,
	transferDone: 6,
	secondFactor: 7,
	resultMessage: 8,
};

const STEPS_WITH_DEFAULT_LOGO = [
	STEPS.selectProvider,
	STEPS.linkMessage,
	// STEPS.enterAmount,
	STEPS.preview,
	STEPS.secondFactor,
];

const STEPS_WITH_BACK_ARROW = [
	STEPS.selectProvider,
	STEPS.exchangeSignIn,
	STEPS.linkMessage,
	STEPS.preview,
	STEPS.transferDone,
];

const REQUIRED_BACK_CALLS = {
	[STEPS.selectProvider]: 0,
	[STEPS.exchangeSignIn]: 0,
	[STEPS.linkMessage]: 2,
	[STEPS.preview]: 1,
	[STEPS.transferDone]: 1,
};

const DepositFromExchange = () => {
	const [step, setStep] = useState(STEPS.depositIntro);
	const [errors, setErrors] = useState([]);
	const [fatalError, setFatalError] = useState('');
	const [searchParams, _] = useSearchParams();
	const [providers, setProviders] = useState([]);
	const {apiBlockmate} = useApiInstances();
	const [provider, setProvider] = useState();
	const providerParam = useMemo(() => searchParams.get('providerName'), [searchParams]);
	const depositId = useMemo(() => searchParams.get('depositId') || searchParams.get('oauthConnectedAccount'), [searchParams]);
	const oauthSuccessParam = useMemo(() => searchParams.get('step'), [searchParams]);
	const depositErrorParam = useMemo(() => searchParams.get('depositError'), [searchParams]);
	const parentUrlParam = useMemo(
		() => searchParams.get('parentUrlEncoded') ? Buffer.from(searchParams.get('parentUrlEncoded'), 'base64').toString()	 : '',
		[searchParams]
	);
	const [finalDepositMessage, setFinalDepositMessage] = useState(null);
	const targetInfo = useMemo(() => {
		const description = searchParams.get('merchantDescription');
		const icon = searchParams.get('merchantIcon');
		return {
			description,
			icon,
		};
	}, [searchParams]);
	const [apiKey, setApiKey] = useState('');
	const [apiSecret, setApiSecret] = useState('');
	const [apiKeyPassphrase, setApiKeyPassphrase] = useState('');
	const [fiatAmount, setFiatAmount] = useState();
	const [fiatCurrency, setFiatCurrency] = useState();
	const [nextStep, setNextStep] = useState({});
	const [prevStep, setPrevStep] = useState({});
	const [headerLogo, setHeaderLogo] = useState(null);
	const [headerDescription, setHeaderDescription] = useState(null);
	const canProceed = errors.length === 0;
	const jwt = useMemo(() => searchParams.get('jwt'), [searchParams]);
	const {authorize, overview, finish, back} = useDeposit(jwt);
	const [authorized, setAuthorized] = useState(false);
	const [authorizeError, setAuthorizeError] = useState();
	const [availableAccounts, setAvailableAccounts] = useState({});
	const [exchangeAccountId, setExchangeAccountId] = useState();
	const [network, setNetwork] = useState();
	const [valueInfo, setValueInfo] = useState();
	const [previewLoaded, setPreviewLoaded] = useState(false);
	const [previewError, setPreviewError] = useState();

	const OAUTH_PROVIDERS = ['coinbase', 'binance', 'bitmarkets', 'bybit'];
	const API_KEY_AUTH_PROVIDER_FIELDS = useMemo(() => (
		{
			kucoin: [
				{
					key: 'api_key',
					label: 'API Key',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'API Secret',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				},
				{
					key: 'api_key_passphrase',
					label: 'Associated passphrase',
					isPasswordType: true,
					value: apiKeyPassphrase,
					setValue: setApiKeyPassphrase,
				},
			],
			bitstamp: [
				{
					key: 'api_key',
					label: 'API Key',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'API Secret',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				},
			],
			bingx: [
				{
					key: 'api_key',
					label: 'API Key',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'Secret Key',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				}
			],
			okx: [
				{
					key: 'api_key',
					label: 'API Key (API)',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'Secret Key',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				},
				{
					key: 'api_key_passphrase',
					label: 'Associated passphrase',
					isPasswordType: true,
					value: apiKeyPassphrase,
					setValue: setApiKeyPassphrase,
				},
			],
			// bybit: [
			// 	{
			// 		key: 'api_key',
			// 		label: 'API Key',
			// 		isPasswordType: false,
			// 		value: apiKey,
			// 		setValue: setApiKey,
			// 	},
			// 	{
			// 		key: 'api_secret',
			// 		label: 'API Secret',
			// 		isPasswordType: true,
			// 		value: apiSecret,
			// 		setValue: setApiSecret,
			// 	}
			// ],
			kraken: [
				{
					key: 'api_key',
					label: 'API Key',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'API Secret',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				}
			],
			gemini: [
				{
					key: 'api_key',
					label: 'API Key',
					isPasswordType: false,
					value: apiKey,
					setValue: setApiKey,
				},
				{
					key: 'api_secret',
					label: 'API Secret',
					isPasswordType: true,
					value: apiSecret,
					setValue: setApiSecret,
				}
			],
		}
	), [apiKey, apiSecret, apiKeyPassphrase]);

	const maybeAddError = (message) => {
		setErrors(prevErrors => {
			if (!prevErrors.includes(message)) {
				return [...prevErrors, message];
			}
			return prevErrors;
		});
	};

	const getProviders = async () => {
		try {
			const response = await apiBlockmate.get('v1/aggregate/account_provider_hints');
			if (response.status === 200) {
				const transformedProviders = (response?.data ?? []).map(providerInfo => ({
					name: providerInfo.account_name,
					description: providerInfo.description,
					oauth: providerInfo.oauth,
					icon: providerInfo.icon,
					url: providerInfo.account_url,
				}));
				setProviders(transformedProviders);
			}
		} catch (err) {
			maybeAddError('Failed to fetch providers.');
		}
	};

	useEffect(() => {
		const prevStepBase = {
			[STEPS.depositIntro]: STEPS.depositIntro,
			[STEPS.selectProvider]: STEPS.depositIntro,
			[STEPS.exchangeSignIn]: STEPS.selectProvider,
			[STEPS.confirmPermissions]: STEPS.exchangeSignIn,
			[STEPS.linkMessage]: STEPS.depositIntro,
			// [STEPS.enterAmount]: STEPS.linkMessage,
			[STEPS.preview]: STEPS.linkMessage,
			[STEPS.transferDone]: STEPS.preview,
			[STEPS.secondFactor]: STEPS.transferDone,
			[STEPS.resultMessage]: STEPS.transferDone,
		};
		const nextStepBase = {
			[STEPS.depositIntro]: STEPS.selectProvider,
			[STEPS.selectProvider]: STEPS.exchangeSignIn,
			[STEPS.exchangeSignIn]: STEPS.confirmPermissions,
			[STEPS.confirmPermissions]: STEPS.linkMessage,
			[STEPS.linkMessage]: STEPS.preview,
			[STEPS.preview]: STEPS.transferDone,
			[STEPS.transferDone]: STEPS.resultMessage,
			[STEPS.secondFactor]: STEPS.resultMessage,
		};
		if (providerParam) {
			setPrevStep({
				...prevStepBase,
				[STEPS.exchangeSignIn]: STEPS.depositIntro,
			});
			setNextStep({
				...nextStepBase,
				[STEPS.depositIntro]: STEPS.exchangeSignIn,
			});
		} else {
			setPrevStep(prevStepBase);
			setNextStep(nextStepBase);
		}
	}, [providerParam]);

	useEffect(() => {
		if (depositErrorParam) {
			setStep(STEPS.resultMessage);
			setFinalDepositMessage(depositErrorParam);
		}
	}, [depositErrorParam]);

	useEffect(() => {
		if (oauthSuccessParam === 'oauth_success') {
			setStep(STEPS.linkMessage);
		}
	}, [oauthSuccessParam]);

	useEffect(() => {
		getProviders();
	}, []);

	useEffect(() => {
		if (providerParam && providers.length > 0) {
			const correctProvider = providers.find(providerInfo => providerInfo.name === providerParam);
			if (correctProvider) {
				setProvider(correctProvider);
			} else {
				maybeAddError('Invalid provider in URL params.');
			}
		}
	}, [providerParam, providers, step]);

	useEffect(() => {
		if (step === STEPS.selectProvider && providerParam) {
			setStep(STEPS.exchangeSignIn);
		}
	}, [step]);

	useEffect(() => {
		if (STEPS_WITH_DEFAULT_LOGO.includes(step)) {
			setHeaderLogo(BlockmateLogoBlack);
			setHeaderDescription(null);
		} else if (step === STEPS.exchangeSignIn && provider) {
			setHeaderLogo(null);
			setHeaderDescription(
				<ProviderLogo provider={provider} />
			);
		} else {
			setHeaderLogo(null);
			setHeaderDescription(null);
		}
	}, [step]);

	useEffect(() => {
		if (depositId && step === STEPS.preview) {
			overview(depositId)
				.then(data => {
					setAvailableAccounts(data?.usable_exchange_accounts);
					setFiatAmount(Number(data?.fiat_amount));
					setFiatCurrency(data?.fiat_currency);
					const foundProvider = (providers ?? []).find(providerInfo => providerInfo.name === data?.name);
					setProvider(foundProvider);
				})
				.catch(error => {
					setPreviewError(error?.response?.data?.detail ?? 'An error occured');
				})
				.finally(() =>
					setPreviewLoaded(true)
				);
		}
	}, [depositId, step]);

	const handleBackArrow = () => {
		const performBackCalls = async () => {
			if (!(step in REQUIRED_BACK_CALLS)) {
				return;
			}
			for (let i = 0; i < REQUIRED_BACK_CALLS[step]; i++) {
				await back(depositId);
			}
		};
		performBackCalls().then(() => setStep(currStep => prevStep[currStep]));
	};

	const _getMerchantUrl = () => {
		const parentUrlBase = parentUrlParam.split('?')[0];
		const parentUrlParams = parentUrlParam.split('?')?.[1] ?? '';
		const params = new URLSearchParams(parentUrlParams);
		const ignoredParams = ['deposit_id', 'providerName', 'oauthConnectedAccount', 'depositError'];
		ignoredParams.forEach(param => {
			if (params.has(param)) {
				params.delete(param);
			}
		});
		let parentUrlFilteredParams = params.size > 0 ? `?${params.toString()}` : '';
		return `${parentUrlBase}${parentUrlFilteredParams}`;
	};

	const handleNextStep = () => {
		setErrors([]);
		if (step === STEPS.depositIntro) {
			if (!valueExists(provider?.name)) {
				setErrors(['Failed to load a provider']);
				return;
			}
			if (Object.keys(API_KEY_AUTH_PROVIDER_FIELDS).includes(provider?.name)) {
				setStep(STEPS.exchangeSignIn);
			} else {
				const merchantUrl = _getMerchantUrl();
				authorize(depositId, provider.name, merchantUrl)
					.then(redirectUrl => {
						if (redirectUrl) {
							handleRedirect(redirectUrl);
						}
					})
					.catch(error => {
						setAuthorizeError(error?.response?.data?.detail ?? 'An error occured');
					})
					.finally(() =>
						setAuthorized(true)
					);
			}
		} else if (step === STEPS.exchangeSignIn) {
			const merchantUrl = _getMerchantUrl();
			const authParams = [depositId, provider.name, merchantUrl];
			if (valueExists(apiKey)) {
				authParams.push(apiKey);
			}
			if (valueExists(apiSecret)) {
				authParams.push(apiSecret);
			}
			if (valueExists(apiKeyPassphrase)) {
				authParams.push(apiKeyPassphrase);
			}
			authorize(...authParams)
				.then(() => setStep(prev => nextStep[prev]))
				.catch(error => setAuthorizeError(error?.response?.data?.detail ?? 'An error occured'))
				.finally(() => setAuthorized(true));
		} else {
			setStep(prev => nextStep[prev]);
		}
	};

	const handleSubmitTx = (secondFactorToken = undefined) => {
		finish(depositId, exchangeAccountId, network, secondFactorToken)
			.then(handleRedirect)
			.catch(error => {
				const maybeDetail = error?.response?.data?.detail;
				if (maybeDetail === '2FA') {
					setStep(STEPS.secondFactor);
				} else if (valueExists(maybeDetail)) {
					setFatalError(maybeDetail);
				} else {
					setFatalError('An error occurred. Please try again.');
				}
			});
	};

	if (valueExists(fatalError)) {
		return <ProviderContext.Provider value={{provider, setProvider}}>
			<div className='modal-container'>
				<div className='modal-content'>
					<ModalHeader withArrow={false} providerLogo={headerLogo} handleClose={() => handleClose()} />
					<ErrorModalScreen message={fatalError} />
				</div>
			</div>
		</ProviderContext.Provider>;
	}

	return <ProviderContext.Provider value={{provider, setProvider}}>
		<div className='modal-container'>
			<div className='modal-content'>
				<ModalHeader
					handleBackArrow={handleBackArrow}
					handleClose={() => handleClose()}
					providerLogo={headerLogo}
					withArrow={
						STEPS_WITH_BACK_ARROW.includes(step) &&
						!(step === STEPS.preview && !previewLoaded) &&
						!(step === STEPS.exchangeSignIn) && // Redirecting to exchange
						!(step === STEPS.linkMessage) // Link message
					}
				>
					{headerDescription}
				</ModalHeader>
				{errors.length > 0 && <Errors errors={errors} />}
				{step === STEPS.depositIntro && (
					<DepositIntro
						targetInfo={targetInfo}
						handleNextStep={handleNextStep}
						isLoading={(providers ?? []).length === 0}
						canProceed={canProceed}
					/>
				)}
				{step === STEPS.selectProvider && (
					<SearchProvider
						onClick={handleNextStep}
						providersData={providers}
						handleBackArrow={handleBackArrow}
						handleClose={handleClose}
						withHeader={false}
					/>
				)}
				{step === STEPS.exchangeSignIn && authorized && valueExists(authorizeError) && (
					<ErrorModalScreen message={authorizeError} />
				)}
				{step === STEPS.exchangeSignIn && !authorized && Object.keys(API_KEY_AUTH_PROVIDER_FIELDS).includes(provider?.name) && (
					<ExchangeSignIn
						provider={provider}
						authFields={API_KEY_AUTH_PROVIDER_FIELDS[provider?.name]}
						handleNextStep={handleNextStep}
						canProceed={canProceed}
					/>
				)}
				{step === STEPS.exchangeSignIn && !authorized && OAUTH_PROVIDERS.includes(provider?.name) && (
					<WaitForRedirect provider={provider} />
				)}
				{step === STEPS.confirmPermissions && (
					<ConfirmPermissions
						targetInfo={targetInfo}
						provider={provider}
						handleNextStep={handleNextStep}
						canProceed={canProceed}
					/>
				)}
				{step === STEPS.linkMessage && (
					<LinkMessage
						isSuccess={true}
						message={`Your provider has been successfully linked to ${targetInfo?.description}`}
						handleNextStep={handleNextStep}
						canProceed={canProceed}
					/>
				)}
				{/*{step === STEPS.enterAmount && (*/}
				{/*	<EnterAmount*/}
				{/*		availableFunds={{*/}
				{/*			value: 0.426,*/}
				{/*			currency: 'ETH',*/}
				{/*			converted_value: 1468.62,*/}
				{/*			converted_currency: 'USD'*/}
				{/*		}}*/}
				{/*		handleNextStep={handleNextStep}*/}
				{/*		canProceed={canProceed}*/}
				{/*	/>*/}
				{/*)}*/}
				{step === STEPS.preview && (
					<Preview
						provider={provider}
						targetInfo={targetInfo}
						availableAccounts={availableAccounts}
						setExchangeAccountId={setExchangeAccountId}
						setNetwork={setNetwork}
						setValueInfo={setValueInfo}
						fiatAmount={fiatAmount}
						fiatCurrency={fiatCurrency}
						handleNextStep={handleNextStep}
						canProceed={canProceed}
						loading={!previewLoaded}
						error={previewError}
					/>
				)}
				{step === STEPS.transferDone && (
					<TransferDone
						provider={provider}
						targetInfo={targetInfo}
						exchangeAccountId={exchangeAccountId}
						valueInfo={valueInfo}
						submitTx={handleSubmitTx}
						canProceed={canProceed}
					/>
				)}
				{step === STEPS.secondFactor && (
					<SecondFactor
						provider={provider}
						submitTx={handleSubmitTx}
					/>
				)}
				{step === STEPS.resultMessage && (
					<FinalMessage
						message={finalDepositMessage}
						handleClose={handleClose}
						successEndResult='depositSuccess'
					/>
				)}
			</div>
		</div>
	</ProviderContext.Provider>;
};

export default DepositFromExchange;
